import React from "react"
import {graphql} from "gatsby"
import styled from "@emotion/styled"
import {GatsbyImage, getImage} from "gatsby-plugin-image"

import Layout from "../../components/layout"
import theme from "../../styles/theme"

const BlogTemplate = ({data}) => {
    const {
        title, author, createdAt, image, childContentfulBlogDescription2TextNode: {
            childMarkdownRemark: {html},
        },
    } = data.contentfulBlog

    const pathToImage = getImage(image)
    const createdAtDate = new Date(createdAt)
    return (<Layout>
        <StyledWrapper>
            <StyledInnerWrapper className="inner center">
                <StyledTitle>{title}</StyledTitle>
                <StyledAuthorWrapper className="margin-bottom">
                    <StyledAuthor>par {author}</StyledAuthor>
                    <StyledDate>
                        le {createdAtDate.toLocaleDateString('fr-FR')}
                    </StyledDate>
                </StyledAuthorWrapper>

                <StyledGatsbyImage
                    className="margin-bottom"
                    image={pathToImage}
                    alt={title}
                    placeholder="blurred"
                />
                <div
                    className="blog"
                    dangerouslySetInnerHTML={{
                        __html: html,
                    }}
                />
            </StyledInnerWrapper>
        </StyledWrapper>
    </Layout>)
}

export const query = graphql`
  query getSingleBlog($title: String) {
    contentfulBlog(title: { eq: $title }) {
      childContentfulBlogDescription2TextNode {
        childMarkdownRemark {
          html  
        }
      }
      title
      author
      createdAt
      image {
        gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
      }
    }
  }
`

const StyledTitle = styled.h1`
    max-width: 40em;
    color: ${theme.palette.common.pink};
    text-align: center;
`

const StyledWrapper = styled.section`
  margin-top: 2em;
  padding-top: calc(2em + ${theme.layout.headerHeight});
  display: flex;
`

const StyledInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
    .blog {
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: ${theme.layout.gridGap};
        color: ${theme.palette.common.black};
        
        img {
            max-width: 100%;
        }
        
        h2, h3, h4 {
            color: ${theme.palette.common.darkBlue};
        }
        
        h3 {
            // color: ${theme.palette.hightlight[1]};
            margin: 1rem 0;
            font-size: 1em;
            
            @media only screen and (min-width: ${theme.breakpoint.small}px) {
              font-size: ${theme.typography.h4};
            }
        }
        
        ul {
            li {
                color: ${theme.palette.common.black};
                font-size: 1.2rem;
                font-weight: 600;
                line-height: 1.4em;
            }
        }
    }
`

const StyledGatsbyImage = styled(GatsbyImage)`
    max-width: 40em;
`

const StyledAuthorWrapper = styled.div`
    max-width: 40em;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4em;
`

const StyledAuthor = styled.div`
    color: ${theme.palette.common.lightGray};
`

const StyledDate = styled.div`
    color: ${theme.palette.common.lightGray};
    font-style: italic;
`

export default BlogTemplate
